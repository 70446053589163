import { Component, Input, OnInit } from '@angular/core';

import {
  BaseForm,
  EntityTypeFieldDto,
  ModuleKeywords,
  PermissionActions,
  TypesWithName,
  getEnumOptions,
  isNullObj,
} from '@shared/classes';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Issue } from '@shared/classes/model/backend/issue';
import { ViewModeService } from '@shared/services/view-mode.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { IssuesDataService } from '../../services/data/issues-data.service';

@Component({
  selector: 'app-issues-item-form',
  templateUrl: './issues-item-form.component.html',
  styleUrls: ['./issues-item-form.component.scss'],
})
export class IssuesItemFormComponent extends BaseForm<Issue> implements OnInit {
  targetTypes = []; // getEnumOptions(TargetTypeEnum);
  listOfStatus = getEnumOptions(Issue.IssueStatusEnum);

  listOfState = getEnumOptions(Issue.StateEnum);

  listOfCriticality = getEnumOptions(Issue.CriticalityEnum);

  categoryList = [EntityTypeFieldDto.CategoryEnum.Responsibility];

  @Input()
  itemId: string;

  @Input()
  searchT: string = null;

  @Input()
  editMode: Boolean = false;
  @Input()
  showCancelButton: Boolean = false;
  constructor(
    private requestService: IssuesDataService,

    public viewModeService: ViewModeService,
    private permissionService: NgxPermissionsService
  ) {
    super(viewModeService, ModuleKeywords.Issue);
  }
  getData() {
    return this.viewModeService.viewMode == 'create'
      ? this.getDataKeyValueFormat()
      : this.getChangedFormValues().updateItems;
  }

  setData(data: any) {
    this.formGroup.patchValue({ ...data });
    this.data = data;

    this.formGroup?.controls?.sourceType.valueChanges.subscribe((data) => {
      this.formGroup?.controls?.sourceCode.setValue(null);
    });
  }
  ngOnInit(): void {
    const perms = this.permissionService.getPermissions();
    const allowedTypeList = [];
    getEnumOptions(TypesWithName).forEach((element) => {
      if (!isNullObj(perms?.[`${PermissionActions.Read}${element.value}`])) {
        allowedTypeList.push(element);
      }
    });
    this.targetTypes = [...allowedTypeList];
  }

  initFormStructure(): void {
    this.formGroup = new FormGroup({
      name: new FormControl(null, Validators.required),
      description: new FormControl(null),
      assignee: new FormControl(null, Validators.required),
      criticality: new FormControl(null, Validators.required),
      issueOwner: new FormControl(null, Validators.required),
      parent: new FormControl(null),
      issueTypeCode: new FormControl(null, Validators.required),
      sourceType: new FormControl(null),
      sourceCode: new FormControl(null),
    });
  }
}
